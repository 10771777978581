import React from "react";
import { Route, Routes } from "react-router-dom";
import { Prudential } from "./Pages/Prudential";
import { LandingPage } from "./Pages/LandingPage";
import { Support } from "./Pages/Support";
import { PageNotFound } from "./Pages/PageNotFound";


function App() {
  return (
    <Routes>
      <Route path="/" element={<PageNotFound />} />
      <Route path="/prudential" element={<LandingPage />} />
      <Route path="/support" element={<Prudential />} />
      <Route path="/requestconfirmation" element={<Support />} />
    </Routes>
  );
}

export default App;
