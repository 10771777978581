import React, { useEffect } from 'react'
import { arrowRight, person_1, person_2, person_3, prudentialLogo, tcareNavbarLogo, tcareNavbarLogoWhite } from '../Components/Images'
import caregiver_burnout from '../Assests/Videos/caregiver_burnout.mp4';
import let_me_know_if_you_need_anything from '../Assests/Videos/let_me_know_if_you_need_anything.mp4';
import caregiver_peace_of_mind from '../Assests/Videos/caregiver_peace_of_mind.mp4';
export const Support = () => {
    useEffect(() => {
        function handleResize() {
            if (window.innerWidth <= 992) {
                document.querySelectorAll('.main_row').forEach((item) => {
                    const main_left = item.querySelector('.main_left')
                    const main_right = item.querySelector('.main_right')
                    main_left.appendChild(main_right)
                })
            } else {
                document.querySelectorAll('.main_row').forEach((item) => {
                    const main_outer = item.querySelector('.main_outer')
                    const main_right = item.querySelector('.main_right')
                    main_outer.appendChild(main_right)
                })
            }

        }
        window.addEventListener('resize', handleResize)
        handleResize()
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    },[])

    const myPlay = (id) => {
        var allVideos = document.querySelectorAll('video')
        allVideos.forEach(e => e.pause())

        var currentVideo = document.getElementById(id);
        currentVideo.play()

        currentVideo.controls = true

    }
    return (
        <React.Fragment>
            <section id='support_section'>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <nav className="navbar navbar-expand-lg bg-transparent my_navbar">
                                <div className="container-fluid">
                                    <a className="navbar-brand" href="/prudential">
                                        <img src={tcareNavbarLogoWhite} alt="tcareNavbarLogo" className='tcareNavbarLogo' />
                                        <div className='navbar_divider'></div>
                                        <img src={prudentialLogo} alt="prudentialLogoBlue" className='prudentialLogoBlue' />
                                    </a>
                                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarText">
                                        {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                        <li className="nav-item">
                                            <a className="nav-link active" aria-current="page" href="#">Home</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#">Features</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#">Pricing</a>
                                        </li>
                                        </ul> */}
                                        <span className="navbar-text ms-auto">
                                            <a href="https://www.tcare.ai" className='visitTcareBtn'>
                                                <img src={arrowRight} alt="" />
                                                Visit TCARE.com
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </nav>

                        </div>
                    </div>
                </div>
            </section>

            <section id='support_hero_section'>
                <div className="container">
                    <div className="row">
                        <div className="col p-0">
                            <div className='hero_main'>
                                <h1>Thanks for connecting!</h1>
                                <p>One of our TCARE specialists will be in touch!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id='support_main_section'>
                <div className="container">
                    <div className="row">
                        <div className="col p-0">
                            <div className="wrapper">
                                <h2 className='heading'>Check out these helpful caregiver resources from our TCARE specialists.</h2>

                                <div className="row main_row">
                                    <div className="col p-0">
                                        <div className='main_outer'>
                                            <div className='main_left'>
                                                <h3>How to Avoid Caregiver Burnout</h3>
                                                <p>
                                                    Caregiver burnout can lead to health declines, depression, and mental health complications for the caregiver. Here are our tips for avoiding burnout and maintaining health while caring for a loved one.
                                                </p>
                                                <div type='button' className='watchVideoBtn' onClick={() => myPlay('confirm_video_1')}>
                                                    <img src={arrowRight} alt="" />
                                                    Watch the Video
                                                </div>
                                            </div>

                                            <div className="main_right">
                                                {/* <img src={person_1} alt="" /> */}
                                                <video id='confirm_video_1' src={caregiver_burnout} controls={false} loop={false} autoPlay={false} poster={person_1}></video>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row main_row">
                                    <div className="col p-0">
                                        <div className='main_outer'>
                                            <div className='main_left'>
                                                <h3>3 Tips for Requesting and Receiving Help</h3>
                                                <p>
                                                    “Let me know if you need anything!” <br />
                                                    We hear and say this all the time. Even though it is well-intentioned, it actually creates another task for the person who needs help. <strong>Here are three tips for caregivers on how to handle requesting and receiving help.</strong>
                                                </p>
                                                <div type='button' className='watchVideoBtn' onClick={() => myPlay('confirm_video_2')}>
                                                    <img src={arrowRight} alt="" />
                                                    Watch the Video
                                                </div>
                                            </div>

                                            <div className="main_right">
                                                {/* <img src={person_2} alt="" /> */}
                                                <video id='confirm_video_2' src={let_me_know_if_you_need_anything} controls={false} loop={false} autoPlay={false} poster={person_2}></video>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row main_row">
                                    <div className="col p-0">
                                        <div className='main_outer'>
                                            <div className='main_left'>
                                                <h3>Safety Tips for Loved Ones with Dementia</h3>
                                                <p>
                                                    Worried your loved one might wander from home and get lost? Here are helpful tips to keep loved ones with dementia safe while providing peace of mind to caregivers.
                                                </p>
                                                <div type='button' className='watchVideoBtn' onClick={() => myPlay('confirm_video_3')}>
                                                    <img src={arrowRight} alt="" />
                                                    Watch the Video
                                                </div>
                                            </div>

                                            <div className="main_right">
                                                {/* <img src={person_3} alt="" /> */}
                                                <video id='confirm_video_3' src={caregiver_peace_of_mind} controls={false} loop={false} autoPlay={false} poster={person_3}></video>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <center>
                                    <a href="/prudential" className='footer_logo'>
                                        <img src={tcareNavbarLogo} alt="" className='mx-auto' />
                                    </a>
                                </center>
                            </div>
                        </div>
                    </div>


                </div>
            </section>

            <section id='support_footer'>
                <div className="container">
                    <div className="row">
                        <div className="col p-0">
                            <div className="wrapper">
                                <div className='footer_div'>
                                    <p>
                                        &copy; Copyright TCARE 2022
                                    </p>

                                    <a href="/#">Other information</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
