import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { landingImg, prudentialLogo } from '../Components/Images'

export const LandingPage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (window.innerWidth < 500) {
      navigate('/support')
    } else {
      document.addEventListener('mousedown', () => {
        window.location.href = '/support'
      })
      setInterval(() => {
        // setShowHome(!showHome)
        window.location.href = '/support'
      }, 4000);
    }

    return () => {
    }
  })




  return (
    window.innerWidth > 500 ?
      <React.Fragment>
        <section id='landing_section'>
          <div className="container-fluid">
            <div className="row">
              <div className="col p-0">
                <div className='landing_div_1'>
                  <div className='welcome_heading'>
                    <h1>Welcome.</h1>
                    <p>We're glad you're here!</p>
                  </div>
                  <a href="/support">
                    <img src={prudentialLogo} alt="prudentialLogo" className='prudentialLogo' width={233} />
                  </a>
                  <img src={landingImg} alt="landingImg" className='landingImg' />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
      :
      ''
  )
}
