import React from 'react'
import { tcareNavbarLogo, prudentialLogoBlue, arrowRight, delivery, appointments, medications, maintenance, preparation, bills, arrowButton, bottom_inner_banner, card_2_Mint, card_2_Ruby, card_2_Periwinkle, card_3_periwinkle, card_3_mint, card_3_darkPurple, card_4_aqua, card_4_purple, card_1_lavender, card_1_ruby, card_1_mint, card_1_periwinkle } from '../Components/Images'
import video_1 from '../Assests/Videos/video_1.mp4';
import video_2 from '../Assests/Videos/video_2.mp4';
import video_3 from '../Assests/Videos/video_3.mp4';

// import recPeriwinkle from '../Assests/Images/recPeriwinkle.png'
// import recLavender from '../Assests/Images/recLavender.png'
// import recMint from '../Assests/Images/recMint.png'
// import recRuby from '../Assests/Images/recRuby.png'

export const Prudential = () => {
  return (
    <React.Fragment>
      <section id='home_section'>
        <div className="container">
          <div className="row">
            <div className="col">
              <nav className="navbar navbar-expand-lg bg-transparent my_navbar">
                <div className="container-fluid">
                  <a className="navbar-brand" href="/prudential">
                    <img src={tcareNavbarLogo} alt="tcareNavbarLogo" className='tcareNavbarLogo' />
                    <div className='navbar_divider'></div>
                    <img src={prudentialLogoBlue} alt="prudentialLogoBlue" className='prudentialLogoBlue' />
                  </a>
                  {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                  </button> */}
                  <div className="collapse navbar-collapse" id="navbarText">
                    {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                      <li className="nav-item">
                        <a className="nav-link active" aria-current="page" href="#">Home</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">Features</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">Pricing</a>
                      </li>
                    </ul> */}
                    {/* <span className="navbar-text ms-auto">
                      Navbar text with an inline element
                    </span> */}
                  </div>
                </div>
              </nav>

            </div>
          </div>
        </div>
      </section>

      <section id='home_hero_section'>
        <div className="container">
          <div className="row">
            <div className="col p-0">
              <div className='hero_outer'>
                <div className='hero_left'>
                  {/* <h1>Make the most of your independence with TCARE.</h1>
                  <p>
                    Maintaining as much independence as possible, for as long as possible, requires a helping hand here and there - from a grocery delivery, to a ride to an appointment, to help with the house.
                    We call these loved ones <strong>caregivers</strong>.
                  </p>
                  <p>
                    As a Prudential Long Term Care policyholder, you and your loved ones have access to a variety of resources that will allow you to maintain and enjoy your independence.
                  </p> */}
                  <h1>TCARE supports your loved ones as they support your independence.</h1>

                  <p>
                    Everybody needs a helping hand from time to time, from rides to appointments to delivering your groceries to help around the house. For you, these helping hands may be a spouse, child or loved one. <strong>We call these helping hands caregivers.</strong>
                  </p>

                  <p>
                    As a Prudential Long Term Care policyholder, you have access to TCARE which provides your caregivers with access to a variety of resources to lighten the load for them, while they lighten the load for you. Access to TCARE is available to you and your caregivers at no additional cost, as part of your existing Prudential Long Term Care policy.
                  </p>

                  <p>
                    <strong>Fill out this form to learn more about this valuable program</strong> and how we are here to help you maintain as much independence as possible, as long as possible!
                  </p>

                  <a href="http://4120352.hs-sites.com/connect" className='tcareConnectBtn'>
                    <img src={arrowRight} alt="arrowRight" />
                    Connect with TCARE
                  </a>
                </div>

                <div className='hero_right'>
                  <video src={video_1} autoPlay muted loop></video>
                  <div className='card_1'>
                    <img src={card_1_lavender} alt="card_1_lavender" className='card_1_lavender' />
                    <img src={card_1_ruby} alt="card_1_ruby" className='card_1_ruby' />
                    <img src={card_1_mint} alt="card_1_mint" className='card_1_mint' />
                    <img src={card_1_periwinkle} alt="card_1_periwinkle" className='card_1_periwinkle' />
                  </div>
                </div>
              </div>


            </div>

          </div>
        </div>
      </section>

      <section id='description_section'>
        <div className="container">
          <div className="row">
            <div className="col desc_col">
              <div className='description'>
                <p>Our care team is here to support what matters to you.</p>
                <a href="http://4120352.hs-sites.com/connect" className='tcareConnectBtn'>
                  <img src={arrowRight} alt="arrowRight" />
                  Connect with a Care Specialist
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='video_2_section'>
        <div className="container">
          <div className="row">
            <div className="col p-0">
              <div className='video_2_outer'>
                <video src={video_2} autoPlay={true} muted loop></video>
                <div className='video_2_inner'>
                  <h3>Lighten the Load</h3>
                  <p>
                    Loved ones of Prudential Long Term Care policyholders have access to a variety of resources designed to <strong>lighten the load for them while they lighten the load for you.</strong> These valuable tools are available through TCARE concierge services to loved ones who provide care and support to you in your home.
                  </p>
                  <p>TCARE concierge services are available to your loved ones at <strong>no additional cost.</strong></p>
                </div>

                <div className='card_2'>
                  <img src={card_2_Mint} alt="" className='card_2_Mint' />
                  <img src={card_2_Ruby} alt="" className='card_2_Ruby' />
                  <img src={card_2_Periwinkle} alt="" className='card_2_Periwinkle' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='tcare_services_section'>
        <div className="container">
          <div className="row">
            <div className="col">
              {/* <h1 className='services_header'>TCARE services</h1> */}
              <h1 className='services_header'>How do your loved ones support you?</h1>
            </div>
          </div>

          <div className="row services_row">
            <div className="col-sm-4 col-md-4 mx-auto">
              <div className='services_card'>
                <span>
                  <img src={delivery} alt="Grocery Delivery" />
                </span>
                <p>Grocery Delivery</p>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 mx-auto">
              <div className='services_card'>
                <span>
                  <img src={appointments} alt="Rides to Appointments" />
                </span>
                <p>Rides to Appointments</p>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 mx-auto">
              <div className='services_card'>
                <span>
                  <img src={medications} alt="Manage Medications" />
                </span>
                <p>Manage Medications</p>
              </div>
            </div>
          </div>

          <div className="row services_row">
            <div className="col-sm-4 col-md-4 mx-auto">
              <div className='services_card'>
                <span>
                  <img src={maintenance} alt="Home Maintenance" />
                </span>
                <p>Home Maintenance</p>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 mx-auto">
              <div className='services_card'>
                <span>
                  <img src={preparation} alt="Meal Preparation" />
                </span>
                <p>Meal Preparation</p>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 mx-auto">
              <div className='services_card'>
                <span>
                  <img src={bills} alt="Paying Bills" />
                </span>
                <p>Paying Bills</p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section id='video_3_section'>
        <div className="container">
          <div className="row">
            <div className="col p-0">
              <div className='video_3_outer'>
                <video src={video_3} autoPlay={true} muted loop></video>
                <div className='video_3_inner'>
                  <h3>Avoid Caregiver Burnout</h3>
                  <p>
                    The TCARE model solves burnout with tailored, easy-to-follow action plans and resources for all of life’s needs.
                  </p>
                  <a href="http://4120352.hs-sites.com/connect" className='tcareConnectBtn'>
                    <img src={arrowRight} alt="arrowRight" />
                    Connect with TCARE
                  </a>
                </div>
                <div className='card_3'>
                  <img src={card_3_periwinkle} alt="" className='card_3_periwinkle' />
                  <img src={card_3_mint} alt="" className='card_3_mint' />
                  <img src={card_3_darkPurple} alt="" className='card_3_darkPurple' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section id='faq_section'>
        <div className="container">
          <div className="row">
            <div className="col p-0">
              <div className="wrapper">
                <h1 className='faq_header'>Frequently Asked Questions</h1>

                <ul>
                  <li>
                    <a href="#my_ans_1" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="my_ans_1">
                      What is TCARE?<img src={arrowButton} alt="arrowButton" /></a>
                    <div id='my_ans_1' className='collapse_div collapse'>
                      <p>
                        In partnership with Prudential, TCARE works with individuals to maintain their independence. Our care specialists build a customized plan for you that reflects your goals.
                      </p>
                    </div>
                  </li>
                  <li>
                    <a href="#my_ans_2" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="my_ans_2">
                      Is there a cost? Why not?<img src={arrowButton} alt="arrowButton" /></a>
                    <div id='my_ans_2' className='collapse_div collapse'>
                      <p>
                        TCARE resources are available to you at no additional cost and are included as part of your Prudential Long Term Care Policy.
                      </p>
                    </div>
                  </li>
                  <li>
                    <a href="#my_ans_3" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="my_ans_3">
                      What types of support does TCARE assist with?<img src={arrowButton} alt="arrowButton" /></a>
                    <div id='my_ans_3' className='collapse_div collapse'>
                      <p>
                        Grocery Delivery, Rides to Appointments, Managing Medications, Home Maintenance, Meal Preparation, Paying Bills
                      </p>
                    </div>
                  </li>
                  <li>
                    <a href="#my_ans_4" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="my_ans_4">
                      I am not a Prudential policy holder but a loved one I care for is, how can I access these resources?<img src={arrowButton} alt="arrowButton" /></a>
                    <div id='my_ans_4' className='collapse_div collapse'>
                      <p>
                        Thank you for the caregiving you are providing to your loved one! Our TCARE resources are designed to lighten the load for caregivers like you. Connect with our TCARE team <span><a href="https://www.tcare.ai/contact">here</a></span> and we’ll walk you through the process.

                      </p>
                    </div>
                  </li>
                  <li>
                    <a href="#my_ans_5" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="my_ans_5">
                      Is there a number I can call for more information?<img src={arrowButton} alt="arrowButton" /></a>
                    <div id='my_ans_5' className='collapse_div collapse'>
                      <p>
                        Yes! You can reach the TCARE team at <br /> <strong>833-931-6616</strong>
                      </p>
                    </div>
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='bottom_section' className=''>
        <div className="container">
          <div className="row">
            <div className="col p-0">
              <div className="wrapper">
                <div className='left'>
                  {/* <img src={bottom_side_banner} alt="bottom_side_banner" className='bottom_side_banner' /> */}
                  <img src={bottom_inner_banner} alt="bottom_inner_banner" className='bottom_inner_banner' />
                </div>

                <div className='right'>
                  <h2>
                    Our care team is here to support what matters to you.
                  </h2>

                  <a href="http://4120352.hs-sites.com/connect" className='tcareConnectBtn'>
                    <img src={arrowRight} alt="arrowRight" />
                    Connect with TCARE
                  </a>

                  {/* <section id='footer_section' className=''>
                    <div className="container">
                      <div className="row">
                        <div className="col p-0"> */}
                  <div className='landing_footer'>
                    <div className='footer_div'>
                      <p>
                        &copy; Copyright TCARE 2022
                      </p>

                      <a href="/#">Other information</a>
                    </div>
                  </div>
                  {/* </div>
                      </div>
                    </div>
                  </section> */}

                </div>

                <div className='card_4 d-none'>
                  <img src={card_4_aqua} alt="card_4_aqua" className='card_4_aqua' />
                  <img src={card_4_purple} alt="card_4_purple" className='card_4_purple' />

                </div>


              </div>
            </div>
          </div>
        </div>
      </section >



    </React.Fragment >
  )
}
